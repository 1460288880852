/**
 * PinImages is meant to offer an accessible way to change the pin images for result pin
 * on the interactive map page. Given some config, an SVG should be customizable to
 * have branding consistent styling in this file.
 */
class PinImages {
  /**
   * @param {Object} defaultPinConfig The configuration for the default pin
   * @param {Object} hoveredPinConfig The configuration for the hovered pin
   * @param {Object} selectedPinConfig The configuration for the selected pin
   */
  constructor(defaultPinConfig = {}, hoveredPinConfig = {}, selectedPinConfig = {}) {
    this.defaultPinConfig = defaultPinConfig;
    this.hoveredPinConfig = hoveredPinConfig;
    this.selectedPinConfig = selectedPinConfig;
  }

  /**
   * Generate standard theme pin given some parameters
   * @param {string} pin.backgroundColor Background color for the pin
   * @param {string} pin.strokeColor Stroke (border) color for the pin
   * @param {string} pin.labelColor Label (text) color for the pin
   * @param {number} pin.width The width of the pin
   * @param {number} pin.height The height of the pin
   * @param {string} pin.pinCount The index of the pin for the pin text
   * @return {Object} The SVG of the pin, and its width and height
   */
  generatePin ({
    backgroundColor = '#00759e',
    strokeColor = 'black',
    labelColor = 'white',
    width = 40,
    height= 47,
    index = '',
    profile = ''
  } = {}) {
    const svg = `
<svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 612 792" style="enable-background:new 0 0 612 792;" xml:space="preserve">
<style type="text/css">
	.st0{fill:none;}
	.st1{fill:#FFFFFF;}
	.st2{fill:#B69679;}
	.st3{fill:#B59579;}
</style>
<path class="st0" d="M308.6,328.3C308.6,328.3,308.6,328.3,308.6,328.3C308.6,328.3,308.6,328.3,308.6,328.3  C308.6,328.3,308.6,328.3,308.6,328.3C308.6,328.3,308.6,328.3,308.6,328.3z"/>
<g>
	<path class="st2" d="M306.9,621.2c-13.9-16.3-27.3-32.2-40.8-48c-31-36.6-61.9-73.2-92.8-109.8c-55.4-65.6-58.1-157.9-4.6-225.2   c38.6-48.6,90.1-71.1,152-66.6c87.6,6.3,157.3,77.2,162.4,164.9c3,50.2-13.5,93.7-46.3,131.7c-42.6,49.4-84.4,99.5-126.6,149.2   C309.3,618.5,308.3,619.6,306.9,621.2z"/>
	<g>
		<circle class="st1" cx="349.9" cy="389.4" r="61.9"/>
		<circle class="st1" cx="263.1" cy="301.1" r="61.9"/>
		<circle class="st1" cx="349.9" cy="301.1" r="61.9"/>
		<circle class="st1" cx="263.1" cy="389.4" r="61.9"/>
		<path class="st3" d="M306.5,345.3c11.2,11,26.5,17.8,43.4,17.8c16.9,0,32.2-6.8,43.4-17.8c-11.2-11-26.5-17.8-43.4-17.8    C333,327.5,317.7,334.3,306.5,345.3z"/>
		<path class="st3" d="M288,301.1c0,17.3,7.1,32.9,18.5,44.1c11.4-11.2,18.5-26.8,18.5-44.1s-7.1-32.9-18.5-44.1    C295.1,268.3,288,283.9,288,301.1z"/>
		<path class="st3" d="M325,389.4c0-17.3-7.1-32.9-18.5-44.1c-11.4,11.2-18.5,26.8-18.5,44.1s7.1,32.9,18.5,44.1    C317.9,422.3,325,406.7,325,389.4z"/>
		<path class="st3" d="M306.5,345.3c-11.2-11-26.5-17.8-43.4-17.8c-16.9,0-32.2,6.8-43.4,17.8c11.2,11,26.5,17.8,43.4,17.8    C280,363.1,295.3,356.3,306.5,345.3z"/>
	</g>
</g>
</svg>`;

    return { svg, width, height };
  };

  /**
   * Get the default pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getDefaultPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.defaultPinConfig.backgroundColor,
      strokeColor: this.defaultPinConfig.strokeColor,
      labelColor: this.defaultPinConfig.labelColor,
      width: 50,
      height: 54,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the hovered pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getHoveredPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.hoveredPinConfig.backgroundColor,
      strokeColor: this.hoveredPinConfig.strokeColor,
      labelColor: this.hoveredPinConfig.labelColor,
      width: 50,
      height: 58,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the selected pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getSelectedPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.selectedPinConfig.backgroundColor,
      strokeColor: this.selectedPinConfig.strokeColor,
      labelColor: this.selectedPinConfig.labelColor,
      width: 50,
      height: 58,
      index: '',
      profile: profile
    });
  }
}

export { PinImages };
